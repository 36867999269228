import { Tooltip } from "@inceptionbg/ui-components";
import clsx from "clsx";
import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FarmContext } from "../AppContent";
import { LandReportDialog } from "../Components/Dialogs/LandReportDialog";
import { AppContext } from "../Context/AppContext";
import { UserContext } from "../Context/UserContext";
import { InfoBox } from "../Elements/Alert/InfoBox";
import headImage from "../assets/images/Dashboard.png";
import deleteImg from "../assets/svg/widgets/Delete.svg";
import holderImg from "../assets/svg/widgets/Holder.svg";
import newImg from "../assets/svg/widgets/New.svg";
import reNewImg from "../assets/svg/widgets/ReNew.svg";
import statementImg from "../assets/svg/widgets/Statement.svg";
import statusImg from "../assets/svg/widgets/Status.svg";
import updateImg from "../assets/svg/widgets/Update.svg";
import { pageNoAccess } from "../utils/PageAccess";

export const DashboardPage: FC = () => {
  const [landReportOpen, setLandReportOpen] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const { farm } = useContext(FarmContext);
  const changeFarm = useContext(FarmContext).changeFarm;
  const { userInfo } = useContext(UserContext);
  const {
    screen: { isMobile },
  } = useContext(AppContext);
  const farmUuid = farm?.uuid;

  const noAccess = pageNoAccess(farm, userInfo);

  const widgets = [
    {
      label: t("AHChangeInfoTitle"),
      desc: t("AHChangeInfoDesc"),
      img: updateImg,
      onClick: () => (farmUuid ? navigate("/edit") : changeFarm()),
      disabled: noAccess.edit,
      tooltip: t("AHChangeInfoTooltip"),
    },
    // {
    //   label: t("RealLandTitle"),
    //   desc: t("RealLandDesc"),
    //   img: newImg,
    //   onClick: () => navigate("/real-lands"),
    //   tooltip: t("RealLandTooltip"),
    //   className:'real-lands',
    // },
    {
      label: t("AHRenewalTitle"),
      desc: t("AHRenewalDesc"),
      img: reNewImg,
      onClick: () => (farmUuid ? navigate("/renew") : changeFarm()),
      disabled: noAccess.renew,
      className: ''
    },
    {
      label: t("AHRegisterTitle"),
      desc: t("AHRegisterDesc"),
      img: newImg,
      onClick: () => navigate("/new"),
      disabled: noAccess.new,
      tooltip: t("AHRegisterTooltip"),
    },
    {
      label: t("AHRegistryDataTitle"),
      desc: t("AHRegistryDataDesc"),
      img: statementImg,
      onClick: () => navigate("/statements"),
      disabled: noAccess.statements,
    },
    {
      label: t("AHChangeStatusTitle"),
      desc: t("AHChangeStatusDesc"),
      img: statusImg,
      onClick: () => navigate("/status-change"),
      disabled: noAccess.statusChange,
    },
    {
      label: t("AHDeleteTitle"),
      desc: t("AHDeleteDesc"),
      img: deleteImg,
      onClick: () => navigate("/delete"),
      disabled: noAccess.delete,
    },

    {
      label: t("AHChangePPHolderTitle"),
      desc: t("AHChangePPHolderDesc"),
      img: holderImg,
      onClick: () => navigate("/change-holder-pp"),
      disabled: noAccess.changeHolderPP,
      tooltip: t("AHChangePPHolderTooltip"),
    },
    {
      label: t("AHSetTempHolderTitle"),
      desc: t("AHSetTempHolderDesc"),
      img: holderImg,
      onClick: () => navigate("/set-temp-holder"),
      disabled: noAccess.setTempHolder,
    },
    {
      label: t("AHChangeLEHolderTitle"),
      desc: t("AHChangeLEHolderDesc"),
      img: holderImg,
      onClick: () => navigate("/change-holder-le"),
    },

    {
      label: t("AHCaseUpdateTitle"),
      desc: t("AHCaseUpdateDesc"),
      img: statusImg,
      onClick: () => navigate("/case-update"),
    },
    {
      label: t("AHBasicRequestsTitle"),
      desc: t("AHBasicRequestsDesc"),
      img: statusImg,
      onClick: () => navigate("/general-request"),
    },
    {
      label: t("AHRejectObjectionTitle"),
      desc: t("AHRejectObjectionDesc"),
      img: statusImg,
      onClick: () => navigate("/complaint"),
    },
    {
      label: t("LandReportTitle"),
      desc: t("LandReportDesc"),
      img: reNewImg,
      onClick: () => setLandReportOpen(true),
      disabled: !farm || farm?.status !== "ACTIVE",
    },
    // {
    //   label: t("FuelInvoices"),
    //   desc: t("InvoicesDesc"),
    //   img: newImg,
    //   onClick: () => navigate("/receipts"),
    // },
    {
      label: t("RefactionTitle"),
      desc: t("RefactionDesc"),
      img: newImg,
      onClick: () => navigate("/refaction"),
    },
    {
      label: t("PesticideProcurements"),
      desc: t("PesticideProcurementsDesc"),
      img: newImg,
      onClick: () => navigate("/pesticide-procurements"),
    },
  ];

  return (
    <>
      <img src={headImage} alt="Head" width="100%" className="mobile-hidden" />
      <div className="page-content">
        <InfoBox
          title={t("DashboardInfoTitle")}
          desc={t("DashboardInfoDesc")}
          className={clsx({ "mx-5 l": !isMobile })}
        />
        <div className="dashboard-widget-container">
          {widgets.map((e) => (
            <Tooltip
              key={e.label}
              label={e.disabled ? e.tooltip : ""}
              position="top"
              className="widget-tooltip"
            >
              <div
                key={e.label}
                className={clsx("widget",e.className, { disabled: e.disabled })}
                onClick={e.disabled ? undefined : e.onClick}
              >
                {e.img && <img src={e.img} alt="Update" />}
                <div>
                  <h2>{e.label}</h2>
                  <p>{e.desc}</p>
                </div>
              </div>
            </Tooltip>
          ))}
        </div>
      </div>
      <LandReportDialog
        isOpen={!!landReportOpen}
        onClose={() => setLandReportOpen(false)}
      />
    </>
  );
};
